

export const authConfig = ()=>{
  console.log('auth config: ' + window.location.host)
    if (window.location.host === 'localhost:8600') {
      return {
        authority: 'https://localhost/oidc',
        redirectUri: 'https://localhost:8600/admin/index.html#/login',
        accessTokenUri: 'https://localhost/oidc/accessToken'
      }
    } else if(window.location.host === 'config-management-pr.id-nonprod.ecg-id.net'){
      const prPath= window.location.pathname.substring(0, window.location.pathname.indexOf('/admin'));
      return {
        authority: 'https://iam-pr.id-nonprod.ecg-id.net'+prPath+'/oidc',
        redirectUri: 'https://config-management-pr.id-nonprod.ecg-id.net'+prPath+'/admin/index.html#/login',
        accessTokenUri: 'https://iam-pr.id-nonprod.ecg-id.net'+prPath+'/oidc/accessToken'
      }
    } else if(/^.{6}-config-management.identity-apollo-dev.heimdall.schip.io$/.test(window.location.host) ){
      const prPath= window.location.pathname.substring(0, window.location.pathname.indexOf('/admin'));
      return {
      	//TODO: Change authority and accessTokenUri to point to our AWS services when deployed
      	//For now, I make them point to non-prod, but if it doesn't work, is not a big problem for now
      	//We will also need to include dev and prod AWS URLs.
      	
        //authority: 'https://iam-pr.id-nonprod.ecg-id.net'+prPath+'/oidc',
        authority: 'https://iam.id-nonprod.ecg-id.net/oidc',
        redirectUri: 'https://'+window.location.host+prPath+'/admin/index.html#/login',
        //accessTokenUri: 'https://iam-pr.id-nonprod.ecg-id.net'+prPath+'/oidc/accessToken'
        accessTokenUri: 'https://iam.id-nonprod.ecg-id.net/oidc/accessToken'
      }
    } else if(window.location.host === 'config-management.id-nonprod.ecg-id.net'){
      return {
        authority: 'https://iam.id-nonprod.ecg-id.net/oidc',
        redirectUri: 'https://config-management.id-nonprod.ecg-id.net/admin/index.html#/login',
        accessTokenUri: 'https://iam.id-nonprod.ecg-id.net/oidc/accessToken'
      }
    } else if (window.location.host === 'config-management.id-prod.ecg-id.net') {
      return {
        authority: 'https://cis.id-prod.ecg-id.net/oidc',
        redirectUri: 'https://config-management.id-prod.ecg-id.net/admin/index.html#/login',
        accessTokenUri: 'https://cis.id-prod.ecg-id.net/oidc/accessToken'
      }
    } else if(window.location.host === 'config-management.identity-apollo-dev.heimdall.schip.io'){
      return {
        //TODO: Change authority and accessTokenUri to point to our AWS services when deployed
        authority: 'https://iam.id-nonprod.ecg-id.net/oidc',
        redirectUri: 'https://config-management.identity-apollo-dev.heimdall.schip.io/admin/index.html#/login',
        accessTokenUri: 'https://iam.id-nonprod.ecg-id.net/oidc/accessToken'
      }
    } else if (window.location.host === 'config-management.identity-apollo-pro.heimdall.schip.io') {
      return {
        //TODO: Change authority and accessTokenUri to point to our AWS services when deployed
        authority: 'https://cis.id-prod.ecg-id.net/oidc',
        redirectUri: 'https://config-management.identity-apollo-dev.heimdall.schip.io/admin/index.html#/login',
        accessTokenUri: 'https://cis.id-prod.ecg-id.net/oidc/accessToken'
      }
    } else {
      return {}
    }
};



