import * as React from "react";
import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  Create,
  Edit,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput
} from "react-admin";
import {Typography} from '@material-ui/core';

export const ClientEdit = (props) => (
    <Edit undoable={false} {...props}>
      <SimpleForm>
        <TextField fullWidth="true" source="client_id" label="Client ID"/>
        <TextField fullWidth="true" source="version" label="Version"/>
        <TextField fullWidth="true" source="created_date" label="Created Date"/>
        <TextField fullWidth="true" source="created_by" label="Created By"/>
        <TextField fullWidth="true" source="last_modified_date" label="Last Modified Date"/>
        <TextField fullWidth="true" source="last_modified_by" label="Last Modified By"/>
        <TextField fullWidth="true" source="client_secret" label="Client secret"/>
        <TextField fullWidth="true" source="is_public_client" label="Is public client?"/>
        <TextInput fullWidth="true" source="client_name" label="Client name"/>
        <TextInput fullWidth="true" source="access_token_expiration_seconds"
                   label="Access Token expiration (seconds)"/>
        <ArrayInput source="redirect_uris" label="Redirect URIs">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput fullWidth="true" source="default_redirect_uri" label="Default redirect URI (overrides the Realm's default redirect URI)"/>
        <ArrayInput source="scopes" label="Scopes">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="audiences" label="Audiences" helperText="Audiences is only used in CIS V2, where they're used to populate the audience ('aud') field in a CIS V2 token.">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <TextField fullWidth="true" source="custom_claim" label="Custom Claims"/>
        <TextInput fullWidth="true" source="custom_claims_namespace" label="Custom Claims Namespace"/>
        <ArrayInput source="custom_claims" label="Static Custom Claims">
          <SimpleFormIterator>
              <TextInput source="name" label="Name"/>
              <ArrayInput source="values" label="Values">
                <SimpleFormIterator>
                  <TextInput label=""/>
                </SimpleFormIterator>
              </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="dynamic_custom_claims" label="Dynamic Custom Claims Allowlist">
          <SimpleFormIterator>
            <TextInput label="Name"/>
          </SimpleFormIterator>
        </ArrayInput>
        <SelectArrayInput source="grant_types" label="Grant types" choices={[
          {id: 'client_credentials', name: 'client_credentials'},
          {id: 'device_code', name: 'device_code'},
          {id: 'refresh_token', name: 'refresh_token'},
          {id: 'password', name: 'password'},
          {id: 'authorization_code', name: 'authorization_code'}
        ]}/>
        <SelectArrayInput label="Response types" source="response_types" choices={[
          {id: 'code', name: 'code'},
          {id: 'id_token', name: 'id_token'},
          {id: 'token', name: 'token'}
        ]}/>
        <ArrayInput source="registration_redirect_uri_patterns" label="Registration: redirect URI patterns">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="password_reset_redirect_uri_patterns" label="Password reset: redirect URI patterns">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="post_logout_redirect_uris" label="Post-logout redirect URIs">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput fullWidth="true" source="auto_login_enabled" label="Auto login enabled?"/>
        <Typography variant="h6" gutterBottom>Refresh Token</Typography>
        <BooleanInput fullWidth="true" source="refresh_token_enabled" label="Enabled"/>
        <BooleanInput fullWidth="true" source="renew_refresh_token" label="Renew"/>
        <TextInput fullWidth="true" source="theme" label="Theme"/>
        <TextInput fullWidth="true" source="frontchannel_logout_uri" label="Front-channel logout URI"/>
        <BooleanInput fullWidth="true" source="frontchannel_logout_session_required"
                      label="Front-channel logout session required"/>
        <TextInput fullWidth="true" source="backchannel_logout_uri" label="Back-channel logout URI"/>
        <BooleanInput fullWidth="true" source="backchannel_logout_session_required"
                      label="Back-channel logout session required"/>
        <TextInput fullWidth="true" source="rendering_application_id" label="Rendering Application ID"/>
        <TextInput fullWidth="true" source="account_confirmation_base_url" label="Account confirmation base url (domain for /confirm-email link)"/>

        <Typography variant="h6" gutterBottom>Multi-factor Authentication</Typography>
        <BooleanInput fullWidth="true" source="two-factor-authentication-enabled" label="MFA Enabled"/>
        <BooleanInput fullWidth="true" source="adaptive_mfa_enabled" label="Adaptive MFA Enabled"/>
        <TextInput fullWidth="true" source="trusted_device_expiration_days" placeholder="30"
                   label="Trusted Device Expiration Duration (days)" />

        <Typography variant="h6" gutterBottom>Breached Password Check</Typography>
        <BooleanInput fullWidth="true" source="breached-password-block-login-enabled" label="Block Login Enabled"/>
        <BooleanInput fullWidth="true" source="breached-password-block-password-change-enabled" label="Block Password Change Enabled"/>

        <Typography variant="h6" gutterBottom>Odin</Typography>
        <TextInput fullWidth="true" source="odin_properties.ecg_property" label="eCG property"/>
        <TextInput fullWidth="true" source="odin_properties.tenant" label="Tenant"/>
        <TextInput fullWidth="true" source="odin_properties.zone_id" label="Zone ID"/>
        <TextInput fullWidth="true" source="odin_properties.platform_name" label="Platform name"/>
        <TextInput fullWidth="true" source="odin_properties.username" label="Username"/>
        <TextInput fullWidth="true" source="odin_properties.password" label="Password"/>

        <Typography variant="h6" gutterBottom>Local Identity Provider</Typography>
        <TextInput fullWidth="true" source="lip_properties.lip_provider_name" label="Local Identity Provider Name"/>

        <Typography variant="h6" gutterBottom>Rendering Host</Typography>
        <TextInput fullWidth="true" source="rendering_properties.rendering_provider_name" label="Rendering Provider Name"/>

        <Typography variant="h6" gutterBottom>Auth methods: Apple</Typography>
        <BooleanInput fullWidth="true" source="auth_methods.apple.enabled" label="Enabled"/>
        <TextInput fullWidth="true" source="auth_methods.apple.client_id" label="Client ID"/>

        <Typography variant="h6" gutterBottom>Auth methods: Google</Typography>
        <BooleanInput fullWidth="true" source="auth_methods.google.api_enabled" label="Sign In with Google via API enabled"/>
        <TextInput fullWidth="true" source="auth_methods.google.api_client_id" label="Google Client ID for Sign In via API"/>

        <Typography variant="h6" gutterBottom>Auth methods: Legacy TokenExchange</Typography>
        <BooleanInput fullWidth="true" source="auth_methods.legacy_token_exchange.enabled" label="Enabled"/>
        <ArrayInput source="auth_methods.legacy_token_exchange.client_ids" label="Client Ids">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="auth_methods.legacy_token_exchange.issuers" label="Issuers">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>

        <Typography variant="h6" gutterBottom>Trust and Safety Properties</Typography>
        <ArrayInput source="tns_properties.property_map_assoc" label="">
          <SimpleFormIterator>
            <TextInput label="Key" source="key"/>
            <TextInput label="Value" source="value"/>
          </SimpleFormIterator>
        </ArrayInput>

      </SimpleForm>
    </Edit>
);

const redirect = (basePath, id, data) => `/realms/${data.realm_id}/show/1`;

export const ClientCreate = (props) => (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput label="Realm" source="realm_id" reference="realms">
          <SelectInput optionText="id"/>
        </ReferenceInput>
        <TextInput fullWidth="true" source="client_name" label="Client name"/>
        <BooleanInput fullWidth="true" source="is_public_client"
                      label="Public client? (No 'client_secret' will be generated)"/>
        <TextInput fullWidth="true" source="access_token_expiration_seconds"
                   label="Access Token expiration (seconds)" initialValue="900"/>
        <ArrayInput source="redirect_uris" label="Redirect URIs">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput fullWidth="true" source="default_redirect_uri" label="Default redirect URI (overrides the Realm's default redirect URI)"/>
        <ArrayInput source="scopes" label="Scopes">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="audiences" label="Audiences" helperText="Audiences is only used in CIS V2, where they're used to populate the audience ('aud') field in a CIS V2 token.">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <TextField fullWidth="true" source="custom_claim" label="Custom Claims"/>
        <TextInput fullWidth="true" source="custom_claims_namespace" label="Custom Claims Namespace"/>
        <ArrayInput source="custom_claims" label="Static Custom Claims">
          <SimpleFormIterator>
            <TextInput source="name" label="Name"/>
            <ArrayInput source="values" label="Values">
              <SimpleFormIterator>
                <TextInput label=""/>
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="dynamic_custom_claims" label="Dynamic Custom Claims Allowlist">
          <SimpleFormIterator>
            <TextInput label="Name"/>
          </SimpleFormIterator>
        </ArrayInput>
        <SelectArrayInput source="grant_types" label="Grant types" choices={[
          {id: 'client_credentials', name: 'client_credentials'},
          {id: 'device_code', name: 'device_code'},
          {id: 'refresh_token', name: 'refresh_token'},
          {id: 'password', name: 'password'},
          {id: 'authorization_code', name: 'authorization_code'}
        ]}/>
        <SelectArrayInput label="Response types" source="response_types" choices={[
          {id: 'code', name: 'code'},
          {id: 'id_token', name: 'id_token'},
          {id: 'token', name: 'token'}
        ]}/>
        <ArrayInput source="registration_redirect_uri_patterns" label="Registration: redirect URI patterns">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="password_reset_redirect_uri_patterns" label="Password reset: redirect URI patterns">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="post_logout_redirect_uris" label="Post-logout redirect URIs">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanField fullWidth="true" source="auto_login_enabled" label="Auto login enabled?"/>
        <Typography variant="h6" gutterBottom>Refresh Token</Typography>
        <BooleanInput fullWidth="true" source="refresh_token_enabled" label="Enabled"/>
        <BooleanInput fullWidth="true" source="renew_refresh_token" label="Renew"/>

        <TextInput fullWidth="true" source="theme" label="Theme"/>
        <TextInput fullWidth="true" source="frontchannel_logout_uri" label="Front-channel logout URI"/>
        <BooleanInput fullWidth="true" source="frontchannel_logout_session_required"
                      label="Front-channel logout session required"/>
        <TextInput fullWidth="true" source="backchannel_logout_uri" label="Back-channel logout URI"/>
        <BooleanInput fullWidth="true" source="backchannel_logout_session_required"
                      label="Back-channel logout session required"/>
        <TextInput fullWidth="true" source="rendering_application_id" label="Rendering Application ID"/>
        <TextInput fullWidth="true" source="account_confirmation_base_url" label="Account confirmation base url (domain for /confirm-email link)"/>

        <Typography variant="h6" gutterBottom>Multi-factor Authentication</Typography>
        <BooleanInput fullWidth="true" source="two-factor-authentication-enabled" label="MFA Enabled"/>
        <BooleanInput fullWidth="true" source="adaptive_mfa_enabled" label="Adaptive MFA Enabled"/>
        <TextInput fullWidth="true" source="trusted_device_expiration_days"
                   label="Trusted Device Expiration Duration (days)" placeholder="30"/>

        <Typography variant="h6" gutterBottom>Breached Password Check</Typography>
        <BooleanInput fullWidth="true" source="breached-password-block-login-enabled" label="Block Login Enabled"/>
        <BooleanInput fullWidth="true" source="breached-password-block-password-change-enabled" label="Block Password Change Enabled"/>

        <Typography variant="h6" gutterBottom>Odin</Typography>
        <TextInput fullWidth="true" source="odin_properties.ecg_property" label="eCG property"/>
        <TextInput fullWidth="true" source="odin_properties.tenant" label="Tenant"/>
        <TextInput fullWidth="true" source="odin_properties.zone_id" label="Zone ID"/>
        <TextInput fullWidth="true" source="odin_properties.platform_name" label="Platform name"/>
        <TextInput fullWidth="true" source="odin_properties.username" label="Username"/>
        <TextInput fullWidth="true" source="odin_properties.password" label="Password"/>

        <Typography variant="h6" gutterBottom>Local Identity Provider</Typography>
        <TextInput fullWidth="true" source="lip_properties.lip_provider_name" label="Local Identity Provider Name"/>

        <Typography variant="h6" gutterBottom>Auth methods: Apple</Typography>
        <BooleanInput fullWidth="true" source="auth_methods.apple.enabled" label="Enabled"/>
        <TextInput fullWidth="true" source="auth_methods.apple.client_id" label="Client ID"/>

        <Typography variant="h6" gutterBottom>Auth methods: Google</Typography>
        <BooleanInput fullWidth="true" source="auth_methods.google.api_enabled" label="Sign In with Google via API enabled"/>
        <TextInput fullWidth="true" source="auth_methods.google.api_client_id" label="Google Client ID for Sign In via API"/>

        <Typography variant="h6" gutterBottom>Auth methods: Legacy TokenExchange</Typography>
        <BooleanInput fullWidth="true" source="auth_methods.legacy_token_exchange.enabled" label="Enabled"/>
        <ArrayInput source="auth_methods.legacy_token_exchange.client_ids" label="Client Ids">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="auth_methods.legacy_token_exchange.issuers" label="Issuers">
          <SimpleFormIterator>
            <TextInput fullWidth="true" label=""/>
          </SimpleFormIterator>
        </ArrayInput>

        <Typography variant="h6" gutterBottom>Trust and Safety Properties</Typography>
        <ArrayInput source="tns_properties.property_map_assoc" label="">
          <SimpleFormIterator>
            <TextInput label="Key" source="key"/>
            <TextInput label="Value" source="value"/>
          </SimpleFormIterator>
        </ArrayInput>

      </SimpleForm>
    </Create>
);
