// in src/App.js
import * as React from "react";
import {Admin, Resource} from "react-admin";
import {ClientCreate, ClientEdit} from "./clients";
import {RealmShow, RealmCreate, RealmEdit, RealmsList} from "./realms";
import dataProvider from "./data_provider";
import AppLayout from "./AppLayout";
import authProvider from "./authProvider";
import SelfServiceLoginPage from './SelfServiceLoginPage'

const App = () => (
    <Admin  authProvider={authProvider} dataProvider={dataProvider} layout={AppLayout} loginPage={SelfServiceLoginPage}>
        <Resource name="realms" list={RealmsList} show={RealmShow} edit={RealmEdit} create={RealmCreate}/>
        <Resource name="clients" edit={ClientEdit} create={ClientCreate}/>
    </Admin>

);

export default App;
