import {UserManager} from 'oidc-client';
import { authConfig } from './authConfig';

const issuer = authConfig().authority;
const clientId = "self-service";
const redirectUri = authConfig().redirectUri;
const accessTokenUri = authConfig().accessTokenUri;


const userManager = new UserManager({
  authority: issuer,
  client_id: clientId,
  redirect_uri: redirectUri,
  response_type: 'code',
  scope: 'openid email profile config-management:realms:read config-management:clients:read',
  extraQueryParams : {'provider' : 'google'}
});


const authProvider = Object.keys(authConfig()).length !== 0 ? {
  login: async (params) => {
    if(localStorage.getItem("accessToken")){
      return Promise.resolve();
    }
    const stateKey = `oidc.${params.state}`;
    const { code_verifier } = JSON.parse(
        localStorage.getItem(stateKey) || '{}'
    );

    const code = params.code;
    const state = params.state;

    if (code && state) {
      const response = await fetch(
          `${accessTokenUri}?code=${code}&code_verifier=${code_verifier}&grant_type=authorization_code&client_id=${clientId}&redirect_uri=${redirectUri}`,
          {
            method: 'POST'
          }
      );

      if (!response.ok) {
        return Promise.reject();
      }
      const token = await response.json();
      localStorage.setItem('accessToken', JSON.stringify(token));
      return Promise.resolve();
    }

    await userManager.signinRedirect();
  },

  // called when the user clicks on the logout button
  logout: async() => {
    console.log("authProvider logout running");
    localStorage.removeItem("accessToken");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: params => {
    if (params instanceof Error && params.message == '[invalid_credentials] Unauthorized') {
      return Promise.reject(new Error('logout'));
    }
    return Promise.resolve();
  },

  checkAuth: async (state, code) => {
    // The next tree logs are for testing purposes and will be deleted after testing in non-prod.
    console.log("accessTokenUri: " + accessTokenUri);
    console.log("redirectUri: " + redirectUri);
    console.log("issuer: " + issuer);
    if(localStorage.getItem("accessToken")){
      return Promise.resolve();
    }
    return Promise.reject()
  },

  getPermissions: () => Promise.resolve(),

} : undefined;

export default authProvider;
