import HttpError from './HttpError';
import {stringify} from 'query-string';

// taken from react-admin's fetchUtils

export const createHeadersFromOptions = (options) => {
    const {access_token} = JSON.parse(
        localStorage.getItem('accessToken') || '{}'
    );

    const requestHeaders = new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer '+access_token,
    });

    const contentTypeNeeded = (opts) => {
        return !(opts && (!opts.method || opts.method === 'GET')) &&
            !(opts && opts.body && opts.body instanceof FormData);
    }

    if (contentTypeNeeded(options)) {
        requestHeaders.set('Content-Type', 'application/json');
    }
    return requestHeaders;
};

export const fetchJson = (url, options) => {
    const requestHeaders = createHeadersFromOptions(options);

    return fetch(url, {...options, headers: requestHeaders})
        .then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        )
        .then(({status, statusText, headers, body}) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }
            if (status < 200 || status >= 300) {
              let msg;
              if (json.errors) {
                msg = json.errors.map(err => { return "[" + err.error + "] " + err.error_description }).join("\n")
              } else {
                msg = "[" + json.error + "] " + json.error_description;
              }
              return Promise.reject(
                  new HttpError(
                      msg,
                      status,
                      json
                  )
              );
            }
            return Promise.resolve({status, headers, body, json});
        });
};

export const queryParameters = stringify;

const isValidObject = (value) => {
    if (!value) {
        return false;
    }

    const isArray = Array.isArray(value);
    const isBuffer = typeof Buffer !== 'undefined' && Buffer.isBuffer(value);
    const isObject =
        Object.prototype.toString.call(value) === '[object Object]';
    const hasKeys = !!Object.keys(value).length;

    return !isArray && !isBuffer && isObject && hasKeys;
};

export const flattenObject = (value, path) => {
    if (isValidObject(value)) {
        return Object.assign(
            {},
            ...Object.keys(value).map(key =>
                flattenObject(value[key], path.concat([key]))
            )
        );
    } else {
        return path.length ? {[path.join('.')]: value} : value;
    }
};
