import {fetchJson} from './fetch';
import config from './config'

const apiUrl = config.api.URL;

const httpClient = fetchJson;

/**
 * Puts the potentially updated values in the 'property_map_assoc' property on 'themes' and 'locales' into the
 * 'property_map' property, so that it can be sent to the API and stored in the DB.
 * See prepareToReceiveRealm() for more info.
 */
const prepareToSendRealm = (realm) => {
    const dataToSend = { ...realm };
    if (realm.themes) {
        dataToSend["themes"] = realm.themes.map(theme => {
            if (theme.property_map_assoc) {
                return {...theme, property_map: theme.property_map_assoc.reduce((obj, item) => ((obj[item.key.trim()] = item.value.trim(), obj)), {})}
            }
            return {...theme, property_map_assoc: {}};
        })
        .map(({property_map_assoc, ...rest}) => rest);
    }
    if (realm.locales) {
        dataToSend["locales"] = realm.locales.map(locale => {
            if (locale.property_map_assoc) {
                return {...locale, property_map: locale.property_map_assoc.reduce((obj, item) => ((obj[item.key.trim()] = item.value.trim(), obj)), {})}
            } else {
                return {...locale, property_map_assoc: {}};
            }
        })
        .map(({property_map_assoc, ...rest}) => rest);
    }

    return dataToSend;
};
/**
 * Adds a new 'property_map_assoc' property to the fields that have a property_map ('themes' and 'locales').
 * This 'property_map_assoc' is an array of key-value objects, e.g.:
 *   [{"key":"test1","value":"testValue1"},{"key":"test2","value":"testValue2"}])
 * This is done so that the property_map can be displayed in the UI by an ArrayInput element.
 */
const prepareToReceiveRealm = (realm) => {
    const dataToReturn = { ...realm };
    if (realm.themes) {
        dataToReturn["themes"] = realm.themes.map(theme => {
            if (theme.property_map) {
                return {...theme, property_map_assoc: Object.keys(theme.property_map).map(item => { return {key: item, value: theme.property_map[item]} })}
            } else {
                return theme;
            }
        });
    }
    if (realm.locales) {
        dataToReturn["locales"] =  realm.locales.map(locale => {
            if (locale.property_map) {
                return {...locale, property_map_assoc: Object.keys(locale.property_map).map(item => { return {key: item, value: locale.property_map[item]} })}
            } else {
                return locale;
            }
        });
    }
    return dataToReturn;
};

/**
 * Puts the potentially updated values in the 'property_map_assoc' property on 'tns_properties' into the
 * 'property_map' property, so that it can be sent to the API and stored in the DB.
 * See prepareToReceiveClient() for more info.
 */
const prepareToSendClient = (client) => {
  const dataToSend = {...client};

  // if there are no tns_properties or the property_map_assoc is empty, just return the client without the tns_properties field
  if (!client.tns_properties || Object.keys(client.tns_properties.property_map_assoc).length === 0) {
    delete dataToSend["tns_properties"];
    return dataToSend;
  }

  // otherwise, fill the property_map field with the data from property_map_assoc
  dataToSend["tns_properties"] = {
    property_map:
        client.tns_properties.property_map_assoc.reduce((obj, item) => ((obj[item.key.trim()] = item.value.trim(), obj)), {})
  }
  return dataToSend;
};
/**
 * Adds a new 'property_map_assoc' property to the 'tns_properties' field.
 * This 'property_map_assoc' is an array of key-value objects, e.g.:
 *   [{"key":"test1","value":"testValue1"},{"key":"test2","value":"testValue2"}])
 * This is done so that the property_map can be displayed in the UI by an ArrayInput element.
 */
const prepareToReceiveClient = (client) => {
  const dataToReturn = {...client};

  // if there are no tns_properties or the property map is empty, just return the client without the tns_properties field
  if (!client.tns_properties || Object.keys(client.tns_properties.property_map).length == 0) {
    delete dataToReturn["tns_properties"];
    return dataToReturn;
  }

  // otherwise, fill the property_map_assoc field with the data from property_map
  dataToReturn["tns_properties"] = {
    ...client.tns_properties,
    property_map_assoc: Object.keys(client.tns_properties.property_map).map(item => {
      return {key: item, value: client.tns_properties.property_map[item]}
    })
  };
  return dataToReturn;
};

function sortArrayByField (array, field) {
  return array.sort((a, b) => (a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1));
}

export default {
    getList: (resource, params) => {
        const url = `${apiUrl}/${resource}`;
        if (resource === 'realms') {
          return httpClient(url).then(({headers, json}) => {
              return {
                // It will be useful addition to have Realms alphabetical ordered (by default) when showing in the UI to avoid randomness.
                data: sortArrayByField([...json], "id"),
                total: parseInt(headers.get('content-range').split('/').pop(), 10),
              }
          });
        }
        return Promise.resolve({
            data: [],
            total: 0,
        });
    },

    getOne: (resource, params) => {
      if (resource === 'realms') {
        const url =`${apiUrl}/realms/${params.id}`;
        return httpClient(url).then(({json}) => {
            return {
                data: prepareToReceiveRealm(json)
            };
        });
      } else {
        const url = `${apiUrl}/realms/${params.id.split("/")[0]}/clients/${params.id.split("/")[1]}`;
        return httpClient(url).then(({json}) => {
            return {
                data: {...prepareToReceiveClient(json), id: json.client_id, realm_id: params.id.split("/")[0]}
            };
        });
      }
    },

    getMany: (resource, params) => {
        return Promise.reject("Not implemented");
    },

    getManyReference: (resource, params) => {
        if (resource === 'realms') {
            return Promise.reject("Not implemented for resource realms");
        }
        const url = `${apiUrl}/realms/${params.id}/clients`;
        return httpClient(url).then(({headers, json}) => {
            if (json.hasOwnProperty("clients")) {
                json = json.clients.map(resource => ({...resource, id: params.id + "/" + resource.client_id}));
            } else if (json.hasOwnProperty("client_id")) {
                json.id = json.client_id;
            } else if (!json) {
                json = [];
            }
            return {
              // It will be useful addition to have Clients alphabetical ordered (by default) when showing in the UI to avoid randomness.
              data: sortArrayByField([...json.map(client => prepareToReceiveClient(client))], "client_id"),
              total: parseInt(headers.get('content-range').split('/').pop(), 10),
            }
        });
    },

    update: (resource, params) => {
        if (resource === 'realms') {
          const url = `${apiUrl}/realms/${params.id}`;
          return httpClient(url, {
              method: 'PUT',
              body: JSON.stringify(prepareToSendRealm(params.data)),
          }).then(({json}) => ({data: prepareToReceiveRealm(json)}));
        } else {
          const url = `${apiUrl}/realms/${params.id.split("/")[0]}/clients/${params.id.split("/")[1]}`;
          return httpClient(url, {
              method: 'PUT',
              body: JSON.stringify(prepareToSendClient(params.data)),
          }).then(({json}) => ({data: {...json, id: params.id}}));
        }
    },

    updateMany: (resource, params) => {
        return Promise.reject("Not implemented");
    },

    create: (resource, params) => {
        if (resource === 'realms') {
          const url = `${apiUrl}/realms`;
          return httpClient(url, {
              method: 'POST',
              body: JSON.stringify(prepareToSendRealm(params.data)),
          }).then(({json}) => {
            return {data: prepareToReceiveRealm(json)};
          });
        } else {
          const url = `${apiUrl}/realms/${params.data.realm_id}/clients`;
          return httpClient(url, {
              method: 'POST',
              body: JSON.stringify(prepareToSendClient(params.data)),
          }).then(({json}) => ({
              data: {...params.data, id: params.data.realm_id + "/" + json.client_id},
          }));
        }
    },

    delete: (resource, params) => {
        if (resource === 'realms') {
            return Promise.reject("Not implemented for resource realms");
        }

        const url = `${apiUrl}/realms/${params.id.split("/")[0]}/clients/${params.id.split("/")[1]}`;
        return httpClient(url, {
            method: 'DELETE',
        }).then(({json}) => ({data: {}}));

    },

    deleteMany: (resource, params) => {
        return Promise.reject("Not implemented");
    },
};
