import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Notification} from 'react-admin';

// Allow multi-line messages to be displayed
const cssMsg = {
    snackbarContent: {
        whiteSpace: 'pre-wrap'
    }
};

const MultilineNotification = withStyles(cssMsg)(({classes, ...props}) => (
    <Notification {...props} className={classes.snackbarContent} autoHideDuration={10000} />
));

export default MultilineNotification;
